@import 'src/styles/helpers';

.benefitsSection {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 64px 0;

    .title {
        padding-left: 24px;
        white-space: break-spaces;
        max-width: 350px;

        @include media(notMobile) {
            max-width: unset;
        }

        @include media(portrait) {
            padding-left: 64px;
        }

        @include media(landscape) {
            padding-left: 72px;
        }

        @include media(desktopAll) {
            padding-left: 320px;
        }

        @include media(desktopSmall) {
            padding-left: 120px;
        }

        @include media(desktop) {
            padding-left: 200px;
        }
    }

    @include media(portrait) {
        padding: 104px 0;
        gap: 92px;
    }

    @include media(landscape) {
        padding: 120px 0;
        gap: 92px;
    }

    @include media(desktopAll) {
        padding: 120px 0;
        gap: 140px;
    }

    @include media(desktopSmall) {
        gap: 92px;
    }
}

.benefitItem {
    height: 100%;
    width: 212px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid;
    @include setProperty(border-color, var(--secondary-400), var(--light-line-shape));
    transition: all ease-in-out 0.5s;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 32px;

        .itemTitle {
            @include setProperty(color, var(--secondary-50), var(--secondary-500));
        }

        .description {
            opacity: 0;
            @include setProperty(color, var(--secondary-100), var(--secondary-400));
            max-height: 200px;
        }
    }

    &.active {
        width: 300px;

        .description {
            opacity: 1;
            animation: showDescription 0.8s linear;
        }
    }

    @include media(desktopAll) {
        padding: 40px 32px;

        &.active {
            width: 490px;
        }
    }
}

@keyframes showDescription {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    90%{
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.slider {
    ul {
        transition: all ease-in-out 0.5s !important;
    }
}